body {
  margin: 0;
  font-family: Verizon-NHG-eTX, Helvetica, Ariel, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-nav-dropdown {
  z-index: 999999;
  position: absolute;
  height: 100vh;
}

.custom-nav-dropdown-ul {
  background-color: #fff;
  border: 1px solid #D8DADA;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.9);
  list-style-type: none;
  margin: 20px 0;
  padding: 0 5px;
  box-sizing: border-box;
}

.custom-nav-dropdown-li {
  color: #000;
  cursor: pointer;
  min-width: 200px;
  margin: 5px 2px;
  padding: 10px;
}

@media screen and (max-width: 1500px) {
  .custom-nav-dropdown {
    right: 0;
  }
}
